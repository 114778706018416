<template lang="">
  <div>
    <div class="my-page container mx-auto mt-5 row">
      <div class="col-md-4 left-content sticky-top">
        <div class="mobile-image-wrapper">
          <img
            src="@/assets/site/images/new/banner.jpg"
            class="img-fluid"
            alt=""
          />
          <a href="#" class="save-button">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
              <path
                d="M496 256c0 137-111 248-248 248-25.6 0-50.2-3.9-73.4-11.1 10.1-16.5 25.2-43.5 30.8-65 3-11.6 15.4-59 15.4-59 8.1 15.4 31.7 28.5 56.8 28.5 74.8 0 128.7-68.8 128.7-154.3 0-81.9-66.9-143.2-152.9-143.2-107 0-163.9 71.8-163.9 150.1 0 36.4 19.4 81.7 50.3 96.1 4.7 2.2 7.2 1.2 8.3-3.3 .8-3.4 5-20.3 6.9-28.1 .6-2.5 .3-4.7-1.7-7.1-10.1-12.5-18.3-35.3-18.3-56.6 0-54.7 41.4-107.6 112-107.6 60.9 0 103.6 41.5 103.6 100.9 0 67.1-33.9 113.6-78 113.6-24.3 0-42.6-20.1-36.7-44.8 7-29.5 20.5-61.3 20.5-82.6 0-19-10.2-34.9-31.4-34.9-24.9 0-44.9 25.7-44.9 60.2 0 22 7.4 36.8 7.4 36.8s-24.5 103.8-29 123.2c-5 21.4-3 51.6-.9 71.2C65.4 450.9 0 361.1 0 256 0 119 111 8 248 8s248 111 248 248z"
              />
            </svg>
            Save
          </a>
        </div>
        <h2 class="name-text">Welcome Home {{ name }}</h2>
        <router-link to="/my-page/edit" class="edit-persona-link">
          <span>Edit</span>
        </router-link>
        <p @click="showPopup()" class="edit-persona-link">
          <span>Persona</span>
          <span>Active Style</span>
        </p>
      </div>
      <div class="content-wrapper col-md-8">
        <app-slider />
        <p v-if="!hasWizardDetails" class="setup-persona-text bold">
          Let’s Setup Your Persona and Active Lifestyle
        </p>
        <div
          v-if="!hasWizardDetails"
          @click="showPopup()"
          class="setup-persona-button"
        >
          Click Here
        </div>
        <div v-if="!hasWizardDetails" class="skip-persona-button">Skip</div>
        <div class="gift-items-container">
          <h3 class="setup-persona-text">
            THESE GIFT ITEMS MATCHES YOUR LIFESTYLE AND PERSONA
          </h3>
          <div class="gift-items">
            <div class="gift-item" v-for="item in wizardNewItems">
              <div class="image-wrapper">
                <img :src="item.imageurl" alt="Gift Item" />
              </div>
              <div class="line"></div>
              <button
                @click="saveClickstreamData(item)"
                class="shop-now-button"
              >
                Shop Now
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="wizardItems.length > 0"
          class="my-page-wizard-items-container"
        >
          <section class="best-sellers-section">
            <div class="best-sellers">
              <div
                @click="ViewProduct(bestSeller)"
                class="best-seller"
                v-for="bestSeller in wizardItems"
              >
                <div class="image-wrapper">
                  <img
                    v-if="bestSeller.imageurl"
                    class="product-image-photo lazyload"
                    :src="bestSeller.imageurl"
                    @error="() => (bestSeller.imageurl = null)"
                    data-src=""
                    loading="lazy"
                    alt="Best Seller Product Image"
                  />
                  <img
                    v-else
                    class="product-image-photo lazyload"
                    src="@/assets/site/images/noimage.jpeg"
                    data-src=""
                    loading="lazy"
                    alt="Best Seller Product Image"
                  />
                  <div class="icons">
                    <div
                      @click.stop="savetoFavourite(bestSeller)"
                      title="Add to Favourite"
                      class="icon"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"
                        />
                      </svg>
                    </div>
                    <div
                      @click.stop="savetoWishlist(bestSeller)"
                      title="Add to Wish List"
                      class="icon"
                    >
                      <svg width="20" height="20" viewBox="0 0 512 512">
                        <path
                          d="M190.5 68.8L225.3 128l-1.3 0-72 0c-22.1 0-40-17.9-40-40s17.9-40 40-40l2.2 0c14.9 0 28.8 7.9 36.3 20.8zM64 88c0 14.4 3.5 28 9.6 40L32 128c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32l448 0c17.7 0 32-14.3 32-32l0-64c0-17.7-14.3-32-32-32l-41.6 0c6.1-12 9.6-25.6 9.6-40c0-48.6-39.4-88-88-88l-2.2 0c-31.9 0-61.5 16.9-77.7 44.4L256 85.5l-24.1-41C215.7 16.9 186.1 0 154.2 0L152 0C103.4 0 64 39.4 64 88zm336 0c0 22.1-17.9 40-40 40l-72 0-1.3 0 34.8-59.2C329.1 55.9 342.9 48 357.8 48l2.2 0c22.1 0 40 17.9 40 40zM32 288l0 176c0 26.5 21.5 48 48 48l144 0 0-224L32 288zM288 512l144 0c26.5 0 48-21.5 48-48l0-176-192 0 0 224z"
                        />
                      </svg>
                    </div>
                    <div
                      @click.stop="compareProduct(bestSeller)"
                      title="Add to Compare"
                      class="icon"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          opacity="0.1"
                          d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z"
                          fill="#fe3c00"
                        />
                        <path
                          opacity="0.1"
                          d="M21 18C21 19.6569 19.6569 21 18 21C16.3431 21 15 19.6569 15 18C15 16.3431 16.3431 15 18 15C19.6569 15 21 16.3431 21 18Z"
                          fill="#fe3c00"
                        />
                        <path
                          d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z"
                          stroke="#fe3c00"
                          stroke-width="2"
                        />
                        <path
                          d="M21 18C21 19.6569 19.6569 21 18 21C16.3431 21 15 19.6569 15 18C15 16.3431 16.3431 15 18 15C19.6569 15 21 16.3431 21 18Z"
                          stroke="#fe3c00"
                          stroke-width="2"
                        />
                        <path
                          d="M15 3L12.0605 5.93945V5.93945C12.0271 5.97289 12.0271 6.02711 12.0605 6.06055V6.06055L15 9"
                          stroke="#fe3c00"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9 21L11.9473 18.0527V18.0527C11.9764 18.0236 11.9764 17.9764 11.9473 17.9473V17.9473L9 15"
                          stroke="#fe3c00"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12 6C14.8284 6 16.2426 6 17.1213 6.87868C18 7.75736 18 9.17157 18 12V15"
                          stroke="#fe3c00"
                          stroke-width="2"
                        />
                        <path
                          d="M12 18C9.17157 18 7.75736 18 6.87868 17.1213C6 16.2426 6 14.8284 6 12L6 9"
                          stroke="#fe3c00"
                          stroke-width="2"
                        />
                      </svg>
                    </div>
                    <div
                      @click.stop="ViewProduct(bestSeller)"
                      title="Quick View"
                      class="icon"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                      >
                        <path
                          d="M572.5 241.4C518.3 135.6 410.9 64 288 64S57.7 135.6 3.5 241.4a32.4 32.4 0 0 0 0 29.2C57.7 376.4 165.1 448 288 448s230.3-71.6 284.5-177.4a32.4 32.4 0 0 0 0-29.2zM288 400a144 144 0 1 1 144-144 143.9 143.9 0 0 1 -144 144zm0-240a95.3 95.3 0 0 0 -25.3 3.8 47.9 47.9 0 0 1 -66.9 66.9A95.8 95.8 0 1 0 288 160z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div class="product-line"></div>
                <a
                  data-post=""
                  @click.stop="selectOccasion(bestSeller)"
                  class="trending-product-wishlist"
                  data-action="add-to-wishlist"
                  title="Add to Favourite"
                >
                  <svg width="20" height="20" viewBox="0 0 512 512">
                    <path
                      d="M190.5 68.8L225.3 128l-1.3 0-72 0c-22.1 0-40-17.9-40-40s17.9-40 40-40l2.2 0c14.9 0 28.8 7.9 36.3 20.8zM64 88c0 14.4 3.5 28 9.6 40L32 128c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32l448 0c17.7 0 32-14.3 32-32l0-64c0-17.7-14.3-32-32-32l-41.6 0c6.1-12 9.6-25.6 9.6-40c0-48.6-39.4-88-88-88l-2.2 0c-31.9 0-61.5 16.9-77.7 44.4L256 85.5l-24.1-41C215.7 16.9 186.1 0 154.2 0L152 0C103.4 0 64 39.4 64 88zm336 0c0 22.1-17.9 40-40 40l-72 0-1.3 0 34.8-59.2C329.1 55.9 342.9 48 357.8 48l2.2 0c22.1 0 40 17.9 40 40zM32 288l0 176c0 26.5 21.5 48 48 48l144 0 0-224L32 288zM288 512l144 0c26.5 0 48-21.5 48-48l0-176-192 0 0 224z"
                    />
                  </svg>
                </a>
                <div class="content">
                  <p class="best-seller-title">
                    {{ bestSeller.productname }}
                  </p>
                  <div class="best-seller-price">
                    {{ bestSeller.saleprice["@currency"] }}
                    {{ bestSeller.saleprice["#text"] }}
                  </div>
                  <div class="best-seller-rating">
                    <svg width="12" height="12" viewBox="0 0 576 512">
                      <path
                        d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                      />
                    </svg>
                    <svg width="12" height="12" viewBox="0 0 576 512">
                      <path
                        d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                      />
                    </svg>
                    <svg width="12" height="12" viewBox="0 0 576 512">
                      <path
                        d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                      />
                    </svg>
                    <svg width="12" height="12" viewBox="0 0 576 512">
                      <path
                        d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                      />
                    </svg>
                    <svg width="12" height="12" viewBox="0 0 576 512">
                      <path
                        d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <my-page-popup
      v-bind:modal_id="modal_id"
      :getProfile="getProfile"
      :wizardDefaults="wizardDefaults"
      :userId="userId"
      :customerId="customerId"
    ></my-page-popup>
  </div>
</template>
<script>
import AppSlider from "@/views/site/blocks/AppSlider";
import MyPagePopup from "@/views/site/mypage/MyPagePopup";

export default {
  name: "MyPageView",
  metaInfo: {
    title: "My Page",
    meta: [
      {
        name: "description",
        content:
          "Discover products tailored to your interests on My Page! Explore a curated selection that aligns with your passions and hobbies, making shopping personalized and enjoyable.",
      },
      {
        name: "keywords",
        content:
          "personalized products, curated selection, interests-based shopping, hobby-related items, tailored recommendations, unique finds, special interests",
      },
    ],
  },
  data() {
    return {
      name: "",
      user: null,
      hasProfile: true,
      modal_id: null,
      userId: null,
      customerId: null,
      wizardItems: [],
      wizardNewItems: [],
      wizardDefaults: null,
      hasWizardDetails: true,
    };
  },
  components: {
    AppSlider,
    MyPagePopup,
  },
  mounted() {
    this.name = JSON.parse(localStorage.getItem("userData")).user.name;

    if (!JSON.parse(localStorage.getItem("userData")))
      return this.$router.push("/login");

    this.getProfile();
  },
  methods: {
    showPopup() {
      this.modal_id = "myPagePopup";
      this.$bvModal.show("myPagePopup");
    },
    selectOccasion(product) {
      var loc_UserData = localStorage.getItem("userData");
      if (loc_UserData == null) {
        //alert("Please log in to continue");
        $("#myModal").modal("show");
      } else {
        this.wishlist_product = product;
        this.$bvModal.show("occasion-modal");
      }
    },
    savetoWishlist(product) {
      var loc_UserData = localStorage.getItem("userData");
      if (loc_UserData == null) {
        //alert("Please log in to continue");
        $("#myModal").modal("show");
      } else {
        //Save or update User Details
        this.$bvModal.hide("occasion-modal");
        var token = localStorage.getItem("userData");
        var userdata_array = JSON.parse(token);

        var api_token = userdata_array.user.api_token;
        var user_id = userdata_array.user.id;
        var fetch_url = process.env.VUE_APP_URL + "customer/userwishlist";
        fetch(fetch_url, {
          method: "post",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer " + api_token,
          },
          body: JSON.stringify({
            product_id: product.productId,
            //product_name:product.productName,
            product: product,
            //price:product.price.text,
            user_id: user_id,
            wishlist_type: "User",
            occasion_id: product.id,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.loader = false;
            this.errors = [];
            if (data.status === true) {
              this.$swal.fire("Good to Go", data.message, "success");
            } else if (data.status === false) {
              this.loader = false;

              this.$swal.fire("Error", data.message, "error");
            } else {
              this.loader = false;
              this.errors = data.errors;
            }
          })
          .catch(function(err) {
            this.$swal("Product", "Error : " + err.message, "error");
          });

        var fetch_url =
          process.env.VUE_APP_URL +
          "customer/products/saveAffilativeNetwork/" +
          this.productId;
        fetch(fetch_url, {
          method: "get",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer " + api_token,
          },
        })
          .then((res) => res.json())
          .then((data) => {});

        this.wishlist_product = null;
      }
    },
    compareProduct(bestSeller) {
      //Save or update User Details
      var loc_UserData = localStorage.getItem("userData");
      if (loc_UserData == null) {
        //alert("Please log in to continue");
        $("#myModal").modal("show");
      } else {
        var token = localStorage.getItem("userData");
        var userdata_array = JSON.parse(token);
        var api_token = userdata_array.user.api_token;
        var user_id = userdata_array.user.id;
        var fetch_url = process.env.VUE_APP_URL + "customer/compare";
        fetch(fetch_url, {
          method: "post",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer " + api_token,
          },
          body: JSON.stringify({
            product_id: bestSeller.productId,
            //product_name:product.productName,
            product: bestSeller,
            //price:product.price.text,
            user_id: user_id,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.loader = false;
            this.errors = [];
            if (data.status === true) {
              this.loader = false;
              this.$swal.fire("Good to Go", data.message, "success");
            } else if (data.status === false) {
              this.loader = false;
              this.$swal.fire("Error", data.message, "error");
            } else {
              this.loader = false;
              this.errors = data.errors;
            }
          })
          .catch(function(err) {
            this.$swal("Product", "Error : " + err.message, "error");
          });
      }
    },
    ViewProduct(bestSeller) {
      this.$store.state.wizard_type = "User";
      const id = Math.random()
        .toString(36)
        .slice(2);
      this.$router.push({
        path: "/single-product/product/" + id,
        query: { singleproduct: bestSeller },
      });
    },
    savetoFavourite(bestSeller) {
      var loc_UserData = localStorage.getItem("userData");
      if (loc_UserData == null) {
        //alert("Please log in to continue");
        $("#myModal").modal("show");
      } else {
        var token = localStorage.getItem("userData");
        var userdata_array = JSON.parse(token);
        var api_token = userdata_array.user.token;
        var user_id = userdata_array.user.id;
        var fetch_url = process.env.VUE_APP_URL + "customer/favourite";
        fetch(fetch_url, {
          method: "post",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer " + api_token,
          },
          body: JSON.stringify({
            product_id: bestSeller.productId,
            product: bestSeller,
            user_id: user_id,
            user_action: "AddToFav",
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.loader = false;
            this.errors = [];
            if (data.status === true) {
              this.loader = false;

              this.$swal.fire("Good to Go", data.message, "success");
            } else if (data.status === false) {
              this.$swal.fire("Error", data.message, "error");
            } else {
              // this.loader = false;
              this.errors = data.errors;
            }
          })
          .catch(function(err) {
            this.$swal("Product", "Error : " + err.message, "error");
          });
      }
    },
    saveClickstreamData(product) {
      var loc_UserData = localStorage.getItem("userData");
      if (loc_UserData == null) {
        $("#myModal").modal("show");
      } else {
        var token = localStorage.getItem("userData");
        var userdata_array = JSON.parse(token);
        var api_token = userdata_array.token;
        var user_id = userdata_array.user.id;
        var country_code = localStorage.getItem("countryCode");
        var countryCode = JSON.parse(country_code);

        var fetch_url =
          process.env.VUE_APP_URL +
          "customer/products/saveAffilativeNetwork/" +
          product.productId;
        fetch(fetch_url, {
          method: "get",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer " + api_token,
          },
        })
          .then((res) => res.json())
          .then((data) => {});

        var fetch_url =
          process.env.VUE_APP_URL + "customer/clickstream/SaveClickDatas";
        fetch(fetch_url, {
          method: "post",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer " + api_token,
          },
          body: JSON.stringify({
            user_id: user_id,
            product_id: product.productId,
            clickstream_data: this.wizarddata,
            user_action: "AddToCart",
            country_code: countryCode,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.loader = false;
            this.errors = [];
            if (data.status === true) {
              this.loader = false;
            } else if (data.status === false) {
              this.loader = false;
              this.$swal.fire("Error", "Product Not saved !", "error");
            } else {
              this.loader = false;
              this.errors = data.errors;
            }
          })
          .catch(function(err) {
            this.$swal("Product", "Error : " + err.message, "error");
          });
        window.open(product.linkurl, "_blank");
      }
    },
    getProfile() {
      this.errors = [];
      const token = JSON.parse(localStorage.getItem("userData"));
      const user_id = token.user.id;
      let method_type = "";
      let fetch_url = "";
      method_type = "post";
      fetch_url = process.env.VUE_APP_URL + "customer/GetCustomerData";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          customer_id: user_id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          this.user = data.data;
          this.userId = data.data.id;
          this.customerId = data.data.customer_id;
          this.hasWizardDetails = !!data.data.wizard_details;
          this.wizardDefaults = data.data.wizard_defaults;
          this.wizardItems = JSON.parse(data.data?.wizard_items)?.items ?? [];
          this.wizardNewItems =
            JSON.parse(data.data?.wizard_items)?.new_items ?? [];
        });
    },
  },
};
</script>
<style>
.my-page {
  padding: 32px 0;
}

.my-page .left-content {
  top: 20px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}

.my-page .mobile-image-wrapper {
  position: relative;
}

.my-page .save-button {
  position: absolute;
  top: 12px;
  left: 12px;
  padding: 4px 12px;
  border-radius: 16px;
  background: #fe3c00;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
}

.my-page .save-button svg {
  flex-shrink: 0;
  fill: #ffffff;
  width: 16px;
  height: 16px;
}

.my-page .name-text {
  padding: 0 16px;
  font-size: 32px;
  font-weight: 700;
  color: #000000;
  text-align: center;
  margin-top: 8px;
}

.my-page .edit-persona-link {
  margin: 0 auto;
  margin-top: 24px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #007bff !important;
  text-align: center;
}

.my-page .edit-persona-link:last-child {
  margin-top: 0;
  cursor: pointer;
}

.my-page .edit-persona-link span {
  line-height: 1.3;
}

.my-page .content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.my-page .content-wrapper .home-slider-main {
  max-width: 100% !important;
  width: 100% !important;
}

.my-page .setup-persona-text {
  margin-bottom: 0;
  font-size: 24px;
  color: #007bff;
  text-align: center;
}

.my-page .setup-persona-text.bold {
  font-weight: 700;
}

.my-page .setup-persona-text.red {
  color: #fe3c00;
}

.my-page .setup-persona-button {
  margin: 0 auto;
  margin-top: 8px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  padding: 8px 24px;
  background: #fe3c00;
  color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.my-page .skip-persona-button {
  margin: 0 auto;
  font-weight: 500;
  font-size: 20px;
  text-decoration: underline;
  cursor: pointer;
}

.gift-items-container {
  width: 100%;
}

.gift-items {
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
}

.gift-items .gift-item {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.gift-items .gift-item .image-wrapper {
  object-fit: cover;
  aspect-ratio: 16 / 9;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #21294b;
  overflow: hidden;
}

.gift-items .gift-item .image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.gift-items .gift-item .line {
  position: relative;
  width: 100%;
  height: 1px;
  margin: 0 auto;
  background: #734386;
}

.gift-items .gift-item .shop-now-button {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 20px !important;
  background-color: transparent !important;
  border: 2px solid #f0bc04 !important;
  outline: none !important;
  color: #000000 !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.my-page-wizard-items-container {
  width: 100%;
}

.best-sellers-section {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.best-sellers-section .best-sellers-section-title {
  font-size: 24px;
  font-weight: 600;
  color: #fe3c00;
  margin-bottom: 12px;
}

.best-sellers-empty-state {
  font-size: 16px;
  font-weight: 500;
  color: #666666;
}

.best-sellers-section .best-sellers {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
}

.best-sellers-section .best-sellers .best-seller {
  position: relative;
  padding: 64px 24px;
  border-radius: 4px;
  border: 1px solid #dadada;
  overflow: hidden;
  cursor: pointer;
}

.best-sellers-section .best-sellers .best-seller:hover {
  border: 1px solid #fe3c00;
}

.best-sellers-section .best-sellers .best-seller .image-wrapper {
  position: relative;
  max-width: 100%;
  height: 240px;
  object-fit: cover;
}

.best-sellers-section .best-sellers .best-seller .image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.best-sellers-section .best-sellers .best-seller:hover .image-wrapper img {
  opacity: 0.25;
}

.best-sellers-section .best-sellers .best-seller .image-wrapper .icons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.best-sellers-section .best-sellers .best-seller:hover .image-wrapper .icons {
  display: flex;
}

.best-sellers-section .best-sellers .best-seller .image-wrapper .icons .icon {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  fill: #fe3c00;
}

.best-sellers-section
  .best-sellers
  .best-seller
  .image-wrapper
  .icons
  .icon
  svg {
  width: 24px;
}

.best-sellers-section .best-sellers .best-seller:hover .image-wrapper .icons {
  display: flex;
}

.best-sellers-section .best-sellers .best-seller .actions {
  position: absolute;
  top: 0;
  left: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.best-sellers-section .best-sellers .best-seller .actions .action {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  transform: rotateX(90deg);
  background: #ffffff;
  color: #fe3c00;
  transform-origin: top;
  cursor: pointer;
  transition: all 0.2s;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.2);
}

.best-sellers-section .best-sellers .best-seller:hover .action {
  transform: rotateX(0);
}

.best-sellers-section .best-sellers .best-seller .actions .action:nth-child(2) {
  transition-delay: rotate 0.1s;
}

.best-sellers-section .best-sellers .best-seller .actions .action:nth-child(3) {
  transition-delay: rotate 0.2s;
}

.best-sellers-section .best-sellers .best-seller .actions .action:last-child {
  transition-delay: rotate 0.3s;
}

.best-sellers-section .best-sellers .best-seller .action:hover {
  background: #fe3c00;
  fill: #ffffff;
}

.best-sellers-section .best-sellers .best-seller .trending-product-wishlist {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  fill: #fe3c00;
  border: 1px solid #fe3c00;
  padding: 4px;
  border-radius: 4px;
}

.best-sellers-section .best-sellers .best-seller .product-line {
  margin: 20px 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(
    to right,
    #dadada 42%,
    #fe3c00 42%,
    #fe3c00 58%,
    #dadada 58%
  );
}

.best-sellers-section .best-sellers .best-seller .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.best-sellers-section .best-sellers .best-seller .content .best-seller-rating {
  display: flex;
  align-items: center;
  gap: 4px;
  fill: #ffc000;
}

.best-sellers-section .best-sellers .best-seller .content .best-seller-title {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 1.3;
  color: #666666;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.best-sellers-section .best-sellers .best-seller .content .best-seller-price {
  color: #fe3c00;
  font-size: 16px;
  font-weight: 500;
}

@media screen and (max-width: 1280px) {
  .gift-items {
    grid-template-columns: repeat(2, 1fr);
  }

  .best-sellers-section .best-sellers {
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
}

@media screen and (max-width: 768px) {
  .my-page .left-content {
    position: static;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 640px) {
  .gift-items {
    grid-template-columns: 1fr;
  }

  .best-sellers-section .best-sellers {
    grid-template-columns: 1fr;
  }

  .best-sellers-section .best-sellers .best-seller .actions .action {
    transform: rotateX(0);
  }
}

@media (max-width: 479px) {
  .left-content .img-fluid {
    max-width: 100% !important;
  }
}
</style>
